import {
  AuctionBiddingEngine,
  AuctionType,
  EAuctionBiddingEngine,
  EAuctionType,
} from '@nx/global-types'
import { cleanDoubleSlashes } from '@nx/populate-url'
import { seoName } from '@nx/seo-name'

import { domainBuilder } from '../domainBuilder/domainBuilder'

export function buildLegacyAuctionPath(auctionId: string | number) {
  return `/auctions/${auctionId}/`
}

type BuildAuctionPathProps = {
  auctionId: string | number
  auctionName: string
  auctionType: EAuctionType
  biddingEngine: EAuctionBiddingEngine
  domain?: string
  slug?: string
}

export function buildAuctionPath({
  auctionId,
  auctionName,
  auctionType,
  biddingEngine,
  domain,
  slug,
}: BuildAuctionPathProps) {
  let path = ''

  if (
    slug &&
    [AuctionBiddingEngine.BRK, AuctionBiddingEngine.BUK].includes(biddingEngine)
  ) {
    let qs = ''
    if (biddingEngine === AuctionBiddingEngine.BRK) {
      domain = 'https://bruun-rasmussen.dk/'
    } else if (biddingEngine === AuctionBiddingEngine.BUK) {
      domain = 'https://www.bukowskis.com/'
      qs = '?utm_source=bonhams.com'
    }
    if (domain) {
      path = `${domain}${slug}${qs}`
      return path
    }
  }

  if (
    auctionType === AuctionType.live &&
    biddingEngine === AuctionBiddingEngine.A3
  ) {
    path = buildLegacyAuctionPath(auctionId)
    if (domain) {
      path = domainBuilder(domain, path)
    }
    return cleanDoubleSlashes(path)
  }

  const seoFriendlyName = seoName(auctionName)
  path = `/auction/${auctionId}/${seoFriendlyName}/`

  if (domain) {
    path = domainBuilder(domain, path)
  }

  return cleanDoubleSlashes(path)
}

export const isAnLDCAuction = (
  auctionType: EAuctionType,
  auctionRunsOnAM: boolean
) => auctionType === AuctionType.live && !auctionRunsOnAM
