import { useEffect, useRef, useState, useCallback } from 'react'

import { MaintenanceMode } from '@nx/global-types'

import { useLoadAuctionMobility } from './useLoadAuctionMobility'
import { EAMConnectionStatus, AMConfig } from '../../types'

export function useInitAuctionMobility({
  config,
  showAMWidget,
  amRowId,
  lotIds,
  connectionStatusCallback,
}: UseInitAuctionMobilityProps): UseInitAuctionMobilityReturn {
  const { amScriptLoaded } = useLoadAuctionMobility(showAMWidget, config)

  const INIT_CALL_MAX_COUNT = 5
  const LOT_LOAD_RETRY_MAX_COUNT = 5

  const doNotLoad =
    !showAMWidget || config.maintenanceMode === MaintenanceMode.partial

  const auctionMobility = useRef<{
    auctionId?: string
    init: () => Promise<unknown>
    layout?: string
    loadLots: (lots: string[]) => void
    loaded?: boolean
    lots?: string[]
    setWidgetHandler?: (
      type: string,
      callback: (data: { websocket_state: EAMConnectionStatus }) => void
    ) => void
    url?: string
    wrapperPrefix?: string
  } | null>()

  const initCallCountRef = useRef(0)
  const [amWidgetLoading, setAMWidgetLoading] = useState(false)
  const [amWidgetLoaded, setAMWidgetLoaded] = useState(
    auctionMobility.current?.loaded || false
  )
  const [isInitErrored, setIsInitErrored] = useState(false)
  const [loadLotsRetryCount, setLoadLotsRetryCount] = useState(0)
  const [stopExecution, setStopExecution] = useState(false)

  // this handy little flatMap removes nulls and turns (string | null)[] into string[]
  const cleanLotIds = lotIds.flatMap((id) => (id ? [id] : []))

  const clearAmWidget = useCallback(() => {
    auctionMobility.current = null
    setAMWidgetLoading(false)
    setAMWidgetLoaded(false)
    setIsInitErrored(false)
    setLoadLotsRetryCount(0)
  }, [])

  useEffect(() => {
    if (doNotLoad) return

    if (loadLotsRetryCount > LOT_LOAD_RETRY_MAX_COUNT) {
      clearAmWidget()
      setStopExecution(true)
      connectionStatusCallback('errored')
    }
  }, [clearAmWidget, connectionStatusCallback, doNotLoad, loadLotsRetryCount])

  useEffect(() => {
    if (doNotLoad) return

    if (isInitErrored) {
      initCallCountRef.current++

      initCallCountRef.current > INIT_CALL_MAX_COUNT
        ? // stop everything if init fails more than 5 times
          setStopExecution(true)
        : // trigger everything to run again in 100 milliseconds
          setTimeout(() => {
            clearAmWidget()
          }, 100)
    }
  }, [isInitErrored, clearAmWidget, doNotLoad])

  useEffect(() => {
    if (doNotLoad) return

    const initAM = async () => {
      if (typeof window !== 'undefined' && window.amw !== undefined) {
        setAMWidgetLoading(true)

        const amWidgetConfig = {
          auctionId: amRowId,
          lots: cleanLotIds,
          url: config.paths['base'],
          wrapperPrefix: AMConfig.wrapperPrefix,
        }

        auctionMobility.current = new window.amw.default.AmWidget(
          amWidgetConfig
        )

        try {
          await auctionMobility.current?.init()
        } catch (error) {
          const shouldTerminate = handleAuctionMobilityError(
            'init',
            error as Error,
            () => setIsInitErrored(true)
          )

          if (shouldTerminate) {
            return
          }
        }

        auctionMobility.current?.setWidgetHandler &&
          auctionMobility.current.setWidgetHandler(
            'system',
            /* istanbul ignore next */
            (data: { websocket_state: EAMConnectionStatus }) => {
              connectionStatusCallback(data.websocket_state)
            }
          )

        setAMWidgetLoaded(true)
        setAMWidgetLoading(false)
      }
    }

    if (auctionMobility.current?.loaded && !amWidgetLoaded) {
      setAMWidgetLoaded(true)
      setAMWidgetLoading(false)
    }

    if (!cleanLotIds.length) return

    const lotsAlreadyLoaded = cleanLotIds.every((value) =>
      auctionMobility.current?.lots?.includes(value)
    )

    if (lotsAlreadyLoaded) return

    if (!amWidgetLoading) {
      if (amWidgetLoaded) {
        try {
          auctionMobility.current?.loadLots(cleanLotIds)
        } catch (error: unknown) {
          handleAuctionMobilityError('loadLots', error as Error, () =>
            setLoadLotsRetryCount((prev) => prev + 1)
          )
        }
      } else {
        /* istanbul ignore else */
        if (amScriptLoaded && !stopExecution) {
          initAM()
        }
      }
    }
  }, [
    amRowId,
    amScriptLoaded,
    amWidgetLoaded,
    amWidgetLoading,
    cleanLotIds,
    connectionStatusCallback,
    doNotLoad,
    stopExecution,
    config.paths,
  ])

  return {
    amWidgetLoaded: auctionMobility.current?.loaded || amWidgetLoaded,
    amWidgetActiveLots: auctionMobility.current?.lots || [],
    clearAmWidget,
  }
}

export function handleAuctionMobilityError(
  type: 'loadLots' | 'init',
  error: Error,
  callback: () => void
): boolean {
  if (type === 'init') {
    if (error.message.match(/Too Many Requests/)) {
      callback()
      return true
    } else {
      return false
    }
  } else {
    callback()
    return false
  }
}

export interface UseInitAuctionMobilityProps {
  config: { maintenanceMode: string; paths: Record<string, string> }
  showAMWidget: boolean
  amRowId: string
  lotIds: (string | null)[]
  connectionStatusCallback: (state: EAMConnectionStatus) => void
}

export interface UseInitAuctionMobilityReturn {
  amWidgetLoaded: boolean
  amWidgetActiveLots: string[]
  clearAmWidget: () => void
}
