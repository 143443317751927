import styled from 'styled-components'

export const StyledIconButton = styled.button`
  all: unset;
  background: ${({ theme }) => theme.colours.primary.white};
  display: grid;
  place-items: center;
  cursor: pointer;
  width: 39px;
  height: 39px;
  border-radius: 50%;

  svg {
    width: 26px;
    height: 26px;
  }
`
