import { useMutation, useQueryClient } from '@tanstack/react-query'

import { followItem } from '../followApi'
import { isGroupedById } from '../get-user-follows.helpers'
import {
  FollowItemProps,
  FollowType,
  GroupedByData,
  GroupedByOutput,
} from '../get-user-follows.types'

export function useFollowItem(baseURL: string) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ userId, type, itemId }: FollowItemProps) => {
      return followItem({
        userId,
        itemId,
        type,
        baseURL,
      })
    },
    onMutate: async ({ userId, type, itemId, metadata }) => {
      await queryClient.cancelQueries({
        queryKey: [`followed-${type}s`, userId],
      })
      const previousFollows = queryClient.getQueryData<GroupedByOutput>([
        `followed-${type}s`,
        userId,
      ])

      queryClient.setQueryData<GroupedByData>(
        [`followed-${type}s`, userId],
        (oldData) => {
          if (!isGroupedById(oldData, type)) {
            return oldData ? [...oldData, itemId] : [itemId]
          }

          // Ensure we're not actually modifying oldData.
          const newQueryData = oldData
            ? (JSON.parse(JSON.stringify(oldData)) as GroupedByOutput)
            : {}

          if (type === FollowType.lot && metadata?.auctionId) {
            if (newQueryData[metadata.auctionId]) {
              newQueryData[metadata.auctionId].push(Number(itemId))
            } else {
              newQueryData[metadata.auctionId] = [Number(itemId)]
            }
          }

          return newQueryData
        }
      )

      return { previousFollows }
    },
    onSettled: (data, _, { userId, type }, context) => {
      // If the mutation fails, use the context returned from onMutate to roll back
      if (data?.status !== 200 && typeof context !== 'undefined') {
        queryClient.setQueryData(
          [`followed-${type}s`, userId],
          context.previousFollows
        )
      }
    },
  })
}
