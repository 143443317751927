import { isAfter, isBefore, isWithinInterval } from 'date-fns'
import { toZonedTime } from 'date-fns-tz'

import {
  AuctionBiddingStatus,
  AuctionProgressStatus,
  AuctionType,
  EAuctionBiddingStatus,
  EAuctionStatus,
  EAuctionType,
  SaleStatus,
} from '@nx/global-types'

const getExhibitionStatus = ({
  nowDate,
  startDate,
  endDate,
}: {
  nowDate: Date
  startDate: Date
  endDate: Date
}): AuctionProgressStatus => {
  if (isAfter(startDate, nowDate)) {
    return AuctionProgressStatus.comingSoon
  } else if (
    isWithinInterval(nowDate, {
      start: startDate,
      end: endDate,
    })
  ) {
    return AuctionProgressStatus.openNow
  } else {
    return AuctionProgressStatus.ended
  }
}

export function getAuctionProgressStatus(auction: {
  endDate: string | number
  startDate: string | number
  localTimezone: string
  status: EAuctionStatus
  type: EAuctionType
  biddingStatus: EAuctionBiddingStatus
  lotCount?: number
  consignmentEndDate: string | number | null
}): AuctionProgressStatus {
  const endDate = toZonedTime(auction.endDate, auction.localTimezone)
  const startDate = toZonedTime(auction.startDate, auction.localTimezone)
  const nowDate = toZonedTime(new Date(), auction.localTimezone)
  const lots = auction.lotCount || 0

  if (auction.type === AuctionType.exhibition) {
    return getExhibitionStatus({ nowDate, startDate, endDate })
  }

  // check if auction is accepting consignments
  if (auction.consignmentEndDate !== null) {
    const consignmentEndDate = toZonedTime(
      auction.consignmentEndDate,
      auction.localTimezone
    )

    if (
      auction.status === SaleStatus.new &&
      isBefore(nowDate, consignmentEndDate)
    ) {
      return lots > 0
        ? AuctionProgressStatus.consignmentReady
        : AuctionProgressStatus.consignment
    }
  }

  // Check if auction is Coming Soon
  if (auction.status === SaleStatus.new) {
    return lots > 0
      ? AuctionProgressStatus.previewReady
      : AuctionProgressStatus.comingSoon
  }

  // Check if auction is Ready.
  if (auction.status === SaleStatus.ready) {
    if (
      auction.type === AuctionType.live &&
      auction.biddingStatus === AuctionBiddingStatus.notStarted
    ) {
      return AuctionProgressStatus.ready
    }

    if (auction.type === AuctionType.online) {
      if (isBefore(nowDate, startDate)) {
        return AuctionProgressStatus.previewReady
      }

      if (isAfter(endDate, nowDate)) {
        return AuctionProgressStatus.ready
      }
    }
  }

  // Check if auction is Live
  if (
    auction.type === AuctionType.live &&
    auction.biddingStatus === AuctionBiddingStatus.inProgress
  ) {
    return AuctionProgressStatus.live
  }

  // Check if auction is Ending (auction end date is in the past)
  if (
    isAfter(nowDate, endDate) &&
    auction.biddingStatus !== AuctionBiddingStatus.ended
  ) {
    return AuctionProgressStatus.ending
  }

  // Check if auction has Ended
  if (
    auction.status === SaleStatus.finished ||
    auction.biddingStatus === AuctionBiddingStatus.ended
  ) {
    return AuctionProgressStatus.ended
  }

  return AuctionProgressStatus.ready
}
