const currencyFormatterFractional = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
})

const currencyFormatterWhole = new Intl.NumberFormat('en-US')

export const formatNumberWithCommas = (number: number) => {
  if (number % 1 === 0 || number === undefined) {
    return currencyFormatterWhole.format(number || 0)
  } else {
    return currencyFormatterFractional.format(number)
  }
}
