import { getTranslate } from '@nx/translations'

import {
  ConnectionLostContainer,
  ConnectionLostTextContainer,
  RefreshButton,
  StyledWarningIcon,
  Subheading,
  Title,
} from './LostAMConnectionNotification.styles'
import translate from './LostAMConnectionNotification.translate.json'

const { text } = getTranslate(translate)

export const LostAMConnectionNotification = ({
  sticky = false,
  inToast = false,
}: {
  sticky?: boolean
  inToast?: boolean
}) => (
  <ConnectionLostContainer $sticky={sticky} $inToast={inToast} role="status">
    <StyledWarningIcon />
    <ConnectionLostTextContainer>
      <Title>{text('title')}</Title>
      <Subheading>{text('body')}</Subheading>
    </ConnectionLostTextContainer>
    <RefreshButton onClick={() => window.location.reload()}>
      {text('button')}
    </RefreshButton>
  </ConnectionLostContainer>
)
