const SEPARATOR = '###LB###'

const getCatalogDescData = (data: string, re: RegExp) => {
  const result = data.replace(/(\r\n|\n|\r)/gm, SEPARATOR).match(re)

  return result?.length && result[0] && result[1] ? result[0] : ''
}

export const extractDataFromHTML = (
  htmlData: string,
  fieldName: string
): string => {
  return getCatalogDescData(
    htmlData,
    RegExp(`<div class=['"]${fieldName}.*?['"]>(.*?)</div>`, 'ms')
  ).replace(RegExp(SEPARATOR, 'g'), '\n')
}
