interface CurrencyIntlFormatProps {
  value: number
  locale: string
  iso: string
  bonhamsCode?: string
}

export function currencyFormat({
  value,
  locale,
  iso,
  bonhamsCode,
}: CurrencyIntlFormatProps) {
  return Intl.NumberFormat(locale, {
    style: 'currency',
    currency: iso,
    maximumFractionDigits: 0,
  })
    .formatToParts(value)
    .map(({ type, value }) =>
      type === 'currency' ? bonhamsCode ?? value : value
    )
    .reduce((str, pt) => str + pt)
}
