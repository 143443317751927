import { useState } from 'react'

import { FireTooltip } from '@nx/fire/tooltip'
import { LotSymbol } from '@nx/global-types'
import { SmartlingAttributes } from '@nx/smartling'
import { getTranslate } from '@nx/translations'

import { SymbolsTable } from '../SymbolsTable'
import {
  LotNumberAndSymbolsContainer,
  StyledLabel,
  StyledSymbolTooltip,
} from './LotNumberAndSymbols.styles'
import translate from './LotNumberAndSymbols.translate.json'

const { text } = getTranslate(translate)

export const LotNumberAndSymbols = ({
  lExhibition,
  iSaleLotNo,
  sSaleLotNoA,
  lotSymbols,
  ...props
}: LotNumberAndSymbolsProps) => {
  const [showTooltip, setShowTooltip] = useState(false)
  let lotLabel = ''

  if (lExhibition) {
    if (iSaleLotNo !== 0) {
      lotLabel = text('item', { id: `${iSaleLotNo}${sSaleLotNoA}` })
    }
  } else {
    if (iSaleLotNo !== 0) {
      lotLabel = text('lot', { id: `${iSaleLotNo}${sSaleLotNoA}` })
    } else {
      lotLabel = text('previewLot')
    }
  }

  const symbols = lotSymbols.map(({ symbol }) => symbol).join()
  const hasSymbols = Boolean(lotSymbols.length)

  return (
    <LotNumberAndSymbolsContainer
      {...SmartlingAttributes.noTranslate}
      {...props}
    >
      <FireTooltip
        showTooltip={showTooltip}
        onCloseClick={() => setShowTooltip(false)}
        tooltip={
          <StyledSymbolTooltip>
            <SymbolsTable symbols={lotSymbols} />
          </StyledSymbolTooltip>
        }
      >
        <span onClick={() => hasSymbols && setShowTooltip(true)}>
          {Boolean(lotLabel) && (
            <StyledLabel $hasSymbols={hasSymbols}>
              {lotLabel}
              {lotSymbols && <sup>{symbols}</sup>}
            </StyledLabel>
          )}
        </span>
      </FireTooltip>
    </LotNumberAndSymbolsContainer>
  )
}

export interface LotNumberAndSymbolsProps {
  lExhibition: boolean
  iSaleLotNo: number
  sSaleLotNoA: string
  lotSymbols: LotSymbol[]
}
