import {
  AuctionBiddingEngine,
  EAuctionBiddingEngine,
  LDCURLLotType,
  URLLotType,
} from '@nx/global-types'
import { cleanDoubleSlashes } from '@nx/populate-url'
import { seoName } from '@nx/seo-name'

import { buildLegacyAuctionPath } from '../buildAuctionPath/buildAuctionPath'
import { domainBuilder } from '../domainBuilder/domainBuilder'

export function buildLegacyLotPath(
  auctionId: number,
  lotId: number | string,
  isPreview: boolean,
  lotCharacter = ''
) {
  const lotType = isPreview ? LDCURLLotType.preview : LDCURLLotType.lot

  return cleanDoubleSlashes(
    `${buildLegacyAuctionPath(auctionId)}/${lotType}/${lotId}${lotCharacter}/`
  )
}

type BuildLotPathProps = {
  auctionId: number | string
  lotId: number | string
  lotName: string
  isPreview?: boolean
  isExhibition?: boolean
  biddingEngine?: EAuctionBiddingEngine
  lotCharacter?: string
  url?: string
  domain?: string
  extraUrlParams?: Record<string, string>
}

export function buildLotPath({
  auctionId,
  lotId,
  lotName,
  isPreview = false,
  isExhibition = false,
  biddingEngine = AuctionBiddingEngine.AM,
  lotCharacter = '',
  url,
  domain,
  extraUrlParams,
}: BuildLotPathProps) {
  let path = ''

  if (url) return url

  if (
    biddingEngine === AuctionBiddingEngine.A3 &&
    typeof auctionId === 'number' &&
    !isExhibition
  ) {
    return buildLegacyLotPath(auctionId, lotId, isPreview, lotCharacter)
  }

  const lotType = isPreview ? URLLotType.preview : URLLotType.lot
  const seoFriendlyName = seoName(lotName)
  const params = extraUrlParams
    ? `?${new URLSearchParams(Object.entries(extraUrlParams)).toString()}`
    : ''
  path = `/auction/${auctionId}/${lotType}/${lotId}${lotCharacter}/${seoFriendlyName}/${params}`

  if (domain) {
    path = domainBuilder(domain, path)
  }

  return cleanDoubleSlashes(path)
}
