import styled, { css } from 'styled-components'

import { Body } from '@nx/fire/typography'

export const StyledPriceContainer = styled.div`
  margin-top: 20px;
`

export const StyledParagraph = styled(Body)`
  ${({ theme }) => theme.fontStyles.sourceSans.medium}
`

export const PriceEstimate = styled(StyledParagraph)<{ $unsold: boolean }>`
  ${({ $unsold, theme }) =>
    $unsold &&
    css`
      color: ${theme.colours.primary.charcoal};
    `}
`

export const PriceEstimateConverted = styled(StyledParagraph)`
  color: ${({ theme }) => theme.colours.grey['75']};
`
