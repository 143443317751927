import { useMutation, useQueryClient } from '@tanstack/react-query'

import { unfollowItem } from '../followApi'
import { isGroupedById } from '../get-user-follows.helpers'
import {
  FollowType,
  GroupedByData,
  GroupedByOutput,
  UnfollowItemProps,
} from '../get-user-follows.types'

export function useUnfollowItem(baseURL: string) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ userId, type, itemId }: UnfollowItemProps) => {
      return unfollowItem({
        userId,
        itemId,
        type,
        baseURL,
      })
    },
    onMutate: async ({ userId, type, itemId, metadata }) => {
      await queryClient.cancelQueries({
        queryKey: [`followed-${type}s`, userId],
      })
      const previousFollows = queryClient.getQueryData<GroupedByOutput>([
        `followed-${type}s`,
        userId,
      ])

      queryClient.setQueryData<GroupedByData>(
        [`followed-${type}s`, userId],
        (oldData) => {
          if (!isGroupedById(oldData, type)) {
            return oldData ? oldData.filter((item) => item !== itemId) : []
          }

          let newQueryData = oldData
            ? (JSON.parse(JSON.stringify(oldData)) as Record<string, number[]>)
            : {}

          if (type === FollowType.lot && newQueryData) {
            if (metadata?.auctionId && newQueryData[metadata.auctionId]) {
              newQueryData[metadata.auctionId] = newQueryData[
                metadata.auctionId
              ].filter((item: number | string) => item !== itemId)

              newQueryData = Object.entries(newQueryData).reduce<
                Record<string, number[]>
              >((accumulator, [key, value]) => {
                if ((value as number[]).length) {
                  accumulator[key] = value as number[]
                }

                return accumulator
              }, {})

              newQueryData = Object.entries(newQueryData).reduce<
                Record<string, number[]>
              >((accumulator, [key, value]) => {
                if (value.length) {
                  accumulator[key] = value
                }

                return accumulator
              }, {})
            }
          }

          return newQueryData
        }
      )

      return { previousFollows }
    },
    onSettled: (data, _, { userId, type }, context) => {
      // If the mutation fails, use the context returned from onMutate to roll back
      if (data?.status !== 200 && typeof context !== 'undefined') {
        queryClient.setQueryData(
          [`followed-${type}s`, userId],
          context.previousFollows
        )
      }
    },
  })
}
