import { ReactNode } from 'react'
import { createPortal } from 'react-dom'

export function Portal({ children }: PortalProps) {
  return createPortal(children, document.body)
}

interface PortalProps {
  children: ReactNode
}
