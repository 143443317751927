import styled, { css } from 'styled-components'

import { WarningIcon } from '@nx/fire/assets'

export const ConnectionLostContainer = styled.div<{
  $sticky: boolean
  $inToast: boolean
}>`
  ${({ theme, $sticky, $inToast }) => css`
    align-items: center;
    background-color: ${theme.colours.status.error};
    color: ${theme.colours.primary.white};
    display: flex;
    flex-direction: row;
    ${$inToast
      ? css`
          margin: 0;
        `
      : css`
          margin-bottom: 20px;
          margin-top: 30px;
        `}
    padding: 15px;

    ${$sticky &&
    css`
      margin: 0;
      padding: 15px;

      ${theme.media.up.lg} {
        padding: 15px 20px;
      }
    `}
  `}
`

export const ConnectionLostTextContainer = styled.div`
  flex: 1;
  padding: 0 0.85rem;
`

export const Title = styled.div`
  ${({ theme }) => theme.typography.body.L}
  color: ${({ theme }) => theme.colours.primary.white};
  line-height: 1;
`

export const Subheading = styled.div`
  ${({ theme }) => theme.typography.body.S}
  color: ${({ theme }) => theme.colours.primary.white};
`

export const RefreshButton = styled.button`
  ${({ theme }) => theme.typography.label.S}

  color: ${({ theme }) => theme.colours.primary.white};
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.colours.primary.white};
  padding: 0;
  background: rgb(255 255 255 / 0%);
  cursor: pointer;

  &:hover {
    border-bottom: 0;
  }
`

export const StyledWarningIcon = styled(WarningIcon)`
  height: 35px;
`
