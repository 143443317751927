import {
  EFollowType,
  FollowType,
  GroupedByData,
  GroupedByOutput,
} from './get-user-follows.types'

export function isGroupedById(
  data: GroupedByData,
  type: EFollowType
): data is GroupedByOutput {
  return FollowType.lot === type
}
