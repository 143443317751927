import { useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import Script from 'next/script'

import { MaintenanceMode } from '@nx/global-types'

export function useLoadAuctionMobility(
  showAMWidget: boolean,
  config: { maintenanceMode: string; paths: Record<string, string> }
) {
  const [amScriptInjected, setAMScriptInjected] = useState(false)
  const [amScriptLoaded, setAMScriptLoaded] = useState(false)
  const doNotLoad =
    !showAMWidget || config.maintenanceMode === MaintenanceMode.partial

  useEffect(() => {
    if (doNotLoad) return

    if (window.amw) {
      setAMScriptInjected(true)
      setAMScriptLoaded(true)
      return
    }

    if (!amScriptInjected) {
      const portalElement = document.getElementById('dynamicScripts')

      if (portalElement) {
        const root = createRoot(portalElement)
        root.render(
          <>
            <link
              key="auction-mobility-css"
              rel="stylesheet"
              href={config.paths['css']}
            />
            <Script
              id="auction-mobility-js"
              src={config.paths['js']}
              strategy="afterInteractive"
              onLoad={() => setAMScriptLoaded(true)}
            />
          </>
        )
        setAMScriptInjected(true)
      }
    }
  }, [doNotLoad, amScriptInjected, config.paths])

  return {
    amScriptLoaded,
  }
}
