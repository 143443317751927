import { ReactElement, cloneElement, useEffect, useRef, useState } from 'react'

import { CloseIcon } from '@nx/fire/assets'

import { Portal } from './components/Portal'
import {
  StyledCloseButton,
  TooltipContainer,
  TooltipContent,
  TooltipHeader,
  TooltipPosition,
  TooltipWrapper,
} from './fire-tooltip.styles'

export function FireTooltip({
  showTooltip,
  children,
  tooltip,
  hideCloseButton,
  onCloseClick,
}: FireTooltipProps) {
  const TOOLTIP_GAP = 10
  const OFFSET_LEFT = 60
  // Push the tooltip this many pixels from the top to not go underneath any of the sticky elements.
  const SAFE_BOUNDARY_TOP = 80
  const [position, setPosition] = useState<TooltipPosition>({
    top: 0,
    left: 0,
    overflow: null,
    arrowPosition: 0,
  })
  const tooltipRef = useRef<HTMLDivElement>(null)
  const childRef = useRef<HTMLElement>()
  const childComponent = cloneElement(children, { ref: childRef })

  // Ensure the tooltip never leaves the screen.
  const restrictRect = (
    position: TooltipPosition,
    rect: { top: number; right: number; bottom: number; left: number }
  ) => {
    const currentPosition = position

    /* istanbul ignore next */
    if (position.left < rect.left) {
      currentPosition.left = rect.left
      currentPosition.overflow = 'horizontal'
    } else if (position.left > rect.right) {
      currentPosition.left = rect.right
      currentPosition.overflow = 'horizontal'
    }

    /* istanbul ignore next */
    if (position.top < rect.top) {
      currentPosition.top = rect.top
      currentPosition.overflow = 'vertical'
    } else if (position.top > rect.bottom) {
      currentPosition.top = rect.bottom
      currentPosition.overflow = 'vertical'
    }

    return currentPosition
  }

  useEffect(() => {
    const handleClickOutside = (event: PointerEvent) => {
      if (
        tooltipRef.current &&
        childRef.current &&
        !tooltipRef.current.contains(event.target as Node) &&
        !childRef.current.contains(event.target as Node)
      ) {
        onCloseClick()
      }
    }

    const updatePosition = () => {
      if (childRef.current && tooltipRef.current) {
        const childRect = childRef.current.getBoundingClientRect()
        const tooltipRect = tooltipRef.current.getBoundingClientRect()

        const currentPosition = restrictRect(
          {
            top:
              childRect.top + childRect.height + window.scrollY + TOOLTIP_GAP,
            left:
              childRect.left +
              (childRect.width - tooltipRect.width) / 2 -
              OFFSET_LEFT,
            overflow: null,
            arrowPosition: 0,
          },
          {
            top: SAFE_BOUNDARY_TOP + TOOLTIP_GAP + window.scrollY,
            left: TOOLTIP_GAP,
            bottom:
              window.innerHeight -
              (tooltipRect.height + TOOLTIP_GAP) +
              window.scrollY,
            right:
              document.body.clientWidth - (tooltipRect.width + TOOLTIP_GAP),
          }
        )

        currentPosition.arrowPosition =
          childRect.x - currentPosition.left + childRect.width / 2

        setPosition(currentPosition)
      }
    }

    if (showTooltip) {
      updatePosition()
      window.addEventListener('resize', updatePosition)
      document.addEventListener('pointerdown', handleClickOutside)
    } else {
      window.removeEventListener('resize', updatePosition)
      document.removeEventListener('pointerdown', handleClickOutside)
    }

    return () => {
      window.removeEventListener('resize', updatePosition)
      document.removeEventListener('pointerdown', handleClickOutside)
    }
  }, [showTooltip, onCloseClick])

  useEffect(() => {
    if (position.top !== 0 && tooltipRef.current) {
      tooltipRef.current.focus()
    }
  }, [position, showTooltip])

  return (
    <TooltipWrapper>
      {childComponent}
      {showTooltip && (
        <Portal>
          <TooltipContainer
            aria-modal="true"
            role="dialog"
            tabIndex={-1}
            ref={tooltipRef}
            $position={position}
          >
            {!hideCloseButton && (
              <TooltipHeader>
                <StyledCloseButton onClick={onCloseClick}>
                  <CloseIcon width="20" height="20" title="Close" />
                </StyledCloseButton>
              </TooltipHeader>
            )}
            <TooltipContent $withTopPadding={!!hideCloseButton}>
              {tooltip}
            </TooltipContent>
          </TooltipContainer>
        </Portal>
      )}
    </TooltipWrapper>
  )
}

export default FireTooltip

export interface FireTooltipProps {
  showTooltip: boolean
  children: ReactElement
  tooltip: ReactElement
  hideCloseButton?: boolean
  onCloseClick: () => void
}
