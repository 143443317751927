import styled, { css } from 'styled-components'

import { Label } from '@nx/fire/typography'

export const LotNumberAndSymbolsContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const StyledLabel = styled(Label)<{ $hasSymbols: boolean }>`
  color: ${({ theme }) => theme.colours.primary.charcoal};
  ${({ theme }) => theme.fontStyles.sourceSans.medium}

  ${({ $hasSymbols, theme }) =>
    $hasSymbols &&
    css`
      margin-top: 15px;
      text-decoration: underline;
      cursor: pointer;

      sup {
        ${theme.typography.body.XS};
        line-height: 1;
        vertical-align: top;
      }

      ${theme.media.up.xs} {
        margin-top: 15px;
      }
    `}
`

export const StyledSymbolTooltip = styled.div`
  td {
    vertical-align: middle;
    min-width: 30px;
  }
`
