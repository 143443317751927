import { AxiosResponse } from 'axios'

import { BonhamsCookies } from '@nx/global-types'
import { createAxiosClient } from '@nx/axios-client'

import {
  FollowItemProps,
  GetFollowedProps,
  UnfollowItemProps,
} from '../get-user-follows.types'

export function getFollowedByType<T>({
  userId,
  type,
  cookies,
  baseURL,
}: GetFollowedProps & { cookies?: BonhamsCookies; baseURL: string }): Promise<
  AxiosResponse<T, unknown>
> {
  const authenticatedAxiosClient = createAxiosClient({
    accessTokenSSR: cookies?.bonhams_access || '',
    refreshTokenSSR: cookies?.bonhams_token || '',
    baseURL,
  })

  return authenticatedAxiosClient.get<T>(`/api/client/${userId}/${type}-likes/`)
}

export function followItem<T>({
  userId,
  itemId,
  type,
  cookies,
  baseURL,
}: FollowItemProps & { cookies?: BonhamsCookies; baseURL: string }): Promise<
  AxiosResponse<T, unknown>
> {
  const authenticatedAxiosClient = createAxiosClient({
    accessTokenSSR: cookies?.bonhams_access || '',
    refreshTokenSSR: cookies?.bonhams_token || '',
    baseURL,
  })

  return authenticatedAxiosClient.post(
    `/api/client/${userId}/${type}-likes/`,
    { sKey: itemId },
    { headers: { 'Content-Type': 'application/json' } }
  )
}

export function unfollowItem<T>({
  userId,
  itemId,
  type,
  cookies,
  baseURL,
}: UnfollowItemProps & { cookies?: BonhamsCookies; baseURL: string }): Promise<
  AxiosResponse<T, unknown>
> {
  const authenticatedAxiosClient = createAxiosClient({
    accessTokenSSR: cookies?.bonhams_access || '',
    refreshTokenSSR: cookies?.bonhams_token || '',
    baseURL,
  })

  return authenticatedAxiosClient.delete(
    `/api/client/${userId}/${type}-likes/`,
    {
      headers: { 'Content-Type': 'application/json' },
      data: { sKey: itemId },
    }
  )
}
