import getConfig from 'next/config'
import { memo, useEffect, useState } from 'react'

import { AuctionResponse, LotResponse } from '@nx/api'
import {
  AMConfig,
  EAMConnectionStatus,
  useInitAuctionMobility,
} from '@nx/external/auction-mobility'
import {
  AuctionBiddingStatus,
  AuctionType,
  MaintenanceMode,
  SaleStatus,
} from '@nx/global-types'

import { AMWidgetWrapper } from './AMWidget.styles'

const { publicRuntimeConfig } = getConfig()

export interface AMWidgetProps {
  auction: AuctionResponse
  lot: LotResponse
  options: {
    showCountdown: boolean
    isUserLoggedIn: boolean
    hasHammerPrice: boolean
  }
  connectionStatusCallback: (status: EAMConnectionStatus) => void
}

export const AMWidget = memo(
  function AMWidget({
    auction,
    lot,
    connectionStatusCallback,
    options,
  }: AMWidgetProps): JSX.Element | null {
    const [showAMWidget, setShowAMWidget] = useState<boolean>(false)

    useEffect(() => {
      const checkOne =
        options.isUserLoggedIn &&
        auction.sBidOnlineStatus === AuctionBiddingStatus.ended
      const checkTwo =
        auction.sSaleType === AuctionType.online
          ? auction.sBidOnlineStatus === AuctionBiddingStatus.inProgress
          : auction.sSaleStatus === SaleStatus.ready &&
            auction.sBidOnlineStatus !== AuctionBiddingStatus.ended
      const doNotRender =
        publicRuntimeConfig.MAINTENANCE_MODE !== MaintenanceMode.partial &&
        (checkOne || checkTwo)

      setShowAMWidget(doNotRender)
    }, [auction, lot, connectionStatusCallback, options])

    useInitAuctionMobility({
      config: {
        maintenanceMode: publicRuntimeConfig.MAINTENANCE_MODE,
        paths: publicRuntimeConfig.AM_PATHS,
      },
      showAMWidget,
      amRowId: lot.amsale_rowid,
      lotIds: [lot.rowid],
      connectionStatusCallback,
    })

    return showAMWidget ? (
      <AMWidgetWrapper $hasHammerPrice={options.hasHammerPrice}>
        <div
          id={`${AMConfig.wrapperPrefix}-${lot.rowid}`}
          lot-id={lot.rowid}
          className="am-data"
        >
          {options.showCountdown && (
            <div>
              <am-timer></am-timer>
            </div>
          )}
          <div>
            <am-lot-status-label></am-lot-status-label>
            <am-bid-status-label></am-bid-status-label>
          </div>
          <div>
            <am-bid-box></am-bid-box>
          </div>
        </div>
      </AMWidgetWrapper>
    ) : null
  },
  (prevProps: AMWidgetProps, nextProps: AMWidgetProps) => {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps)
  }
)
