import styled, { css } from 'styled-components'

export interface TooltipPosition {
  top: number
  left: number
  overflow: 'horizontal' | 'vertical' | null
  arrowPosition: number
}

export const TooltipWrapper = styled.div`
  position: relative;
  line-height: 0;
`

export const TooltipContainer = styled.div<{
  $position: TooltipPosition
}>`
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(
    ${({ $position }) => $position.left}px,
    ${({ $position }) => $position.top}px,
    0
  );
  will-change: transform;
  max-width: 400px;
  z-index: ${({ theme }) => theme.zIndex.popup};
  margin-right: 20px;
  padding: 5px;
  box-shadow: ${({ theme }) => theme.shadows.small};
  background-color: ${({ theme: { colours } }) => colours.primary.white};
  border-radius: 3px;
  outline: none;
  ${({ theme: { colours }, $position }) =>
    $position.overflow !== 'vertical' &&
    css`
      &::before {
        content: '';
        border-style: solid;
        border-width: 0 10px 10px;
        border-color: transparent transparent ${colours.primary.white}
          transparent;
        position: absolute;
        left: ${$position.arrowPosition}px;
        top: -10px;
      }
    `}
`

export const TooltipHeader = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const TooltipContent = styled.div<{ $withTopPadding: boolean }>`
  padding: ${({ $withTopPadding }) =>
    $withTopPadding ? '10px' : '0 10px 10px 10px'};
`

export const StyledCloseButton = styled.button`
  all: unset;
  cursor: pointer;
  width: 25px;
  height: 25px;
  display: grid;
  place-items: center;

  &:focus,
  &:hover {
    color: ${({ theme }) => theme.colours.primary.primary};
  }
`
