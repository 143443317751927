import { Hit } from '@algolia/client-search'

import {
  EAuctionBiddingEngine,
  EAuctionBiddingStatus,
  EAuctionStatus,
  EAuctionType,
  EBrand,
} from '@nx/global-types'

import { ImageValue, LegacyImageValue } from '../api'

export enum LotStatus {
  withdrawn = 'WD',
  withoutResults = 'WR',
  unsold = 'BI',
  sold = 'SOLD',
  cancelled = 'CS',
  noLot = 'NL',
  new = 'NEW',
}

export type ELotStatus = `${LotStatus}`

export interface LotHighlight {
  auctionId: number
  lotNumber: string
  previewLot: boolean
  lotIdUnique: number
  name: string
  image: ImageValue
  hammerPrice: number
  hammerPremium: number
  currencySymbol: string
  currencySymbol2: string
  currencySymbol3: string
  estimateLow?: number
  estimateHigh?: number
  referToDepartment?: boolean
  publishResults?: boolean
}

export interface AuctionLot {
  objectID: string
  auctionAMId: string
  auctionType: EAuctionType
  auctionId: number | string
  biddingEngine: EAuctionBiddingEngine
  department: {
    code: string
    name: string
  }
  lotUniqueId: number
  lotItemId: number | string
  lotId: string
  lotAMId: string
  styledDescription: string
  lotNo: { number: number; letter: string }
  flags: {
    isPreview: boolean
    isResultPublished: boolean
    isExhibition: boolean
    isReferToDepartment: boolean
    isWithoutReserve: boolean
    isAmended: boolean
    isToBeSold: boolean
    hasConditionReport: boolean
  }
  status: ELotStatus
  price: {
    currencySymbol: string
    hammerPrice: number
    hammerPremium: number
    estimateLow: number
    estimateHigh: number
    GBPLowEstimate: number
    GBPHighEstimate: number
  }
  currency: {
    iso_code: string
    bonhams_code: string
  }
  image: LegacyImageValue | ImageValue | null
  heading: string
  title: string
  symbols: LotSymbol[]
  hammerTime: {
    datetime: string
    timezone: {
      iana: string
      abbreviation: string
    }
    timestamp: number
  }
  biddableFrom?: {
    datetime: string
    timezone: {
      iana: string
      abbreviation: string
    }
    timestamp: number
  } | null
  brand: EBrand
  url?: string
  country: {
    code: string
    name: string
  }
  groups: string[]
  auctionStatus: EAuctionStatus
  biddingStatus: EAuctionBiddingStatus
}

export interface LotSymbol {
  symbol: string
  shortDescription: string
  longDescription: string
}

export interface LotData {
  auctionLots: Hit<AuctionLot>[] | null
  pagesOfLots: Hit<AuctionLot>[][] | null
  nbHits: number
  lowestPrice: number | null
  highestPrice: number | null
  departments: string[] | null
  groups?: string[] | null
  currencySymbol: string | null
  countries?: string[] | null
  facets?: Record<string, Record<string, number>> | undefined
}
