export enum SaleStatus {
  cancel = 'CANCEL',
  complete = 'COMP',
  finished = 'FINISHED',
  new = 'NEW',
  postponed = 'POSTPONE',
  ready = 'READY',
  settled = 'SETTLE',
}

export type EAuctionStatus = `${SaleStatus}`

export enum AuctionType {
  online = 'ONLINE',
  private = 'PRIAUC',
  live = 'PUBLIC',
  exhibition = 'EXHIBITION',
}

export type EAuctionType = `${AuctionType}`

// This indicates whether a logged in customer is allowed to bid online on an auction.
// It is applicable to both Live and Online auctions.
//
// The difference is that for PUBLIC auctions, staff manually set this field when the auction has finished,
// e.g. within 1-2 minutes of the auction ending, and if staff forget to set it then the system will do
// it for them automatically within 5 minutes. There is a cron job to get the results from AM every
// 5 minutes, and if that job finds all lots have had a result and the bid online status is not
// already set to EN, then it will do it.
//
// For online auctions, staff do not set it manually, but as part of the cron job to fetch AM results,
// it automatically gets set to the right status.
export enum AuctionBiddingStatus {
  ended = 'EN',
  inProgress = 'IP',
  notStarted = 'NS',
  paused = 'PD',
}

export type EAuctionBiddingStatus = `${AuctionBiddingStatus}`

export enum AuctionBiddingEngine {
  AM = 'AM',
  A3 = 'A3',
  BRK = 'bruun_rasmussen',
  BUK = 'bukowskis',
}

export type EAuctionBiddingEngine = `${AuctionBiddingEngine}`

export enum AuctionProgressStatus {
  consignment = 'consignment',
  comingSoon = 'coming-soon',
  openNow = 'open-now',
  previewReady = 'preview-ready',
  consignmentReady = 'consignment-ready',
  ready = 'ready',
  live = 'live',
  ending = 'ending',
  ended = 'ended',
}

export const auctionSearchParameters = [
  'page',
  'query',
  'countries',
  'departments',
  'groups',
  'months',
  'auctionTypes',
  'sortBy',
  'price',
  'categories',
  'monthsAndYears',
  'followed',
] as const

export type AuctionSearchParametersProps =
  (typeof auctionSearchParameters)[number]
