export enum AMConnectionStatus {
  connected = 'connected',
  disconnected = 'disconnected',
  errored = 'errored',
}

export type EAMConnectionStatus = `${AMConnectionStatus}`

export enum AMConfig {
  wrapperPrefix = 'am-target',
  minTimeBetweenRequests = 1500, // At the time of writing AM can only handle loading 50 lots at a time and only once every 1.5 seconds
}

declare global {
  interface Window {
    amw?: {
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      default: any
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'am-timer': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >
      'am-lot-status-label': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >
      'am-bid-status-label': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >
      'am-bid-box': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >
      'am-list-bid-status-label': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >
      'am-list-lot-status-label': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >
      'am-list-bid-box': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >
    }
  }
}
