export enum FollowType {
  artist = 'artist',
  auction = 'sale',
  department = 'department',
  lot = 'lot',
}

export type EFollowType = `${FollowType}`

export interface GetFollowedProps {
  userId: number
  type: EFollowType
}

export type FollowedArtistsResponse = {
  iArtistNo: number
}[]

export type FollowedAuctionsResponse = {
  iSaleNo: number
}[]

export interface FollowedDepartmentsResponse {
  sDepartment: string
}

export type FollowedLotsResponse = {
  iSaleItemNo: number
  auctionId: number
}[]

export interface FollowItemProps {
  userId: number
  itemId: number | string
  type: EFollowType
  metadata?: {
    auctionId?: number | string
  }
}

export type UnfollowItemProps = FollowItemProps

export interface UnfollowedItemSuccessResponse {
  MessageStatus: 'SUCCESS'
  Message: string
}

export type GroupedByOutput = Record<string | number, number[]>
export type GroupedByData = (string | number)[] | GroupedByOutput | undefined
