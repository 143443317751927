import styled from 'styled-components'

import {
  baseFireButtonStyles,
  styledPrimaryButtonStyles,
} from '@nx/fire/button'

const WIN_COLOR = '#0dab62'
const LOST_COLOR = '#ce2d4f'

export const AMWidgetWrapper = styled.div<{ $hasHammerPrice?: boolean }>`
  .am-data {
    .am-timer {
      margin-top: 20px;

      .label {
        ${({ theme }) => theme.typography.body.M}
        ${({ theme }) => theme.fontStyles.sourceSans.medium}
        padding: 0 0 5px !important;
      }

      .time-wrapper {
        padding-bottom: 0;
      }
    }

    .am-lot-status-label {
      [class='sold'] {
        display: ${({ $hasHammerPrice }) =>
          $hasHammerPrice ? 'none' : 'block'};
      }

      .lost.outbid,
      .win.sold,
      .lost.sold {
        padding-left: 0;
      }

      .win.sold,
      .lost.sold {
        padding-bottom: 0;
      }

      .lost.outbid,
      .lost.sold {
        color: ${LOST_COLOR};
      }

      .win.sold {
        color: ${WIN_COLOR};
      }
    }

    .am-bid-status-label {
      > div:first-child {
        padding-left: 0;
        padding-right: 0;
      }

      .current-label,
      .current-bid {
        width: auto;
        ${({ theme }) => theme.typography.body.L}
        ${({ theme }) => theme.fontStyles.sourceSans.medium}
        color: ${({ theme }) => theme.colours.primary.primary};
      }

      .current-label {
        margin-right: 30px;
      }

      .win.winning {
        .current-label,
        .current-bid {
          color: ${WIN_COLOR};
        }
      }
    }

    .am-bid-box {
      margin-top: 20px;

      > div:first-child {
        padding: 0;
      }

      .bid-button:not(.selected-price) {
        ${baseFireButtonStyles({ $variant: 'primary', $size: 'M' })};
        ${styledPrimaryButtonStyles};
        width: 100%;
        min-width: 150px;

        ${({ theme }) => theme.media.up.sm} {
          width: fit-content;
        }
      }

      button.bid-button:not(.selected-price) {
        margin-top: 20px;
      }
    }
  }
`
