import { BonhamsCookies } from '@nx/global-types'
import { logger } from '@nx/utils/logger'

import { getFollowedByType } from '../followApi'
import {
  FollowType,
  FollowedDepartmentsResponse,
} from '../get-user-follows.types'

export async function getFollowedDepartments(
  userId: number,
  cookies: BonhamsCookies,
  baseURL: string
): Promise<FollowedDepartmentsResponse['sDepartment'][]> {
  try {
    const response = await getFollowedByType<FollowedDepartmentsResponse[]>({
      userId,
      type: FollowType.department,
      cookies,
      baseURL,
    })

    return response.data.map((item) => item.sDepartment)
  } catch (error) {
    logger.error(error, `Could not fetch department likes for user ${userId}`)
    return []
  }
}
