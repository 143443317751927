import { Fragment } from 'react'

import { Body, Label } from '@nx/fire/typography'
import { LotSymbol } from '@nx/global-types'

export interface SymbolsTableProps {
  symbols: LotSymbol[]
}

export function SymbolsTable({ symbols }: SymbolsTableProps) {
  return (
    <table>
      <tbody>
        {symbols.map(({ symbol, shortDescription, longDescription }) => (
          <Fragment key={symbol}>
            <tr>
              <Body
                $level="L"
                as="td"
                dangerouslySetInnerHTML={{
                  __html: symbol,
                }}
                css={`
                  padding-right: 10px;
                `}
              />
              <Label $level="M" as="td">
                {shortDescription}
              </Label>
            </tr>
            <tr>
              <td
                css={`
                  padding-right: 10px;
                `}
              />
              <Body
                $level="M"
                as="td"
                dangerouslySetInnerHTML={{
                  __html: longDescription,
                }}
              />
            </tr>
          </Fragment>
        ))}
      </tbody>
    </table>
  )
}
